import React from 'react';
import PropTypes from 'prop-types';
import { getImage , GatsbyImage  } from "gatsby-plugin-image";
import "../style/global-style.sass";
import useWindowSize from '../hooks/useWindowSize';

//Helper component for Main title
function SubTitle({fragment , color}){
    return(
        <span style={{ color }} >{fragment + ' '}</span>
    )
}

SubTitle.propTypes = {
    fragment: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

const BusinessImpact = ({impact}) => {

    const image =getImage(impact.hexagonalImg);
    const { width } = useWindowSize();

    return(
        <div id="next-section" className='businessImpact-main-container section4'>
            <div className='businessImpact-main-container1'>
                <div className='businessImpact-main-title has-text-weight-semibold'>
                    {impact.title.map((item , index) => (
                        <SubTitle key={index} {...item}/>
                    ))}
                </div>
                <div className='businessImpact-main-des'>
                    <p className='businessImpact-main-des-sub' style={{fontFamily: impact.fontFamily}}>{impact.description}</p>
                </div>
            </div>

            <div className='businessImpact-hexagonal-main-container'>
                <div className='businessImpact-hexagonal-sub-container'>
                    <div className='businessImpact-impact-image'>
                            {image ? (
                                <GatsbyImage image={image} alt='Loading...' className='setImage'/>
                                ) : (
                                <img src={impact.hexagonalImg} alt='Loading...' className='setImage' />
                                )}
                    </div>
                    {impact.hexagonal.map((value,i) => {
                        const logo = getImage(value.logo)
                    return(
                        <div className='businessImpact-sub-child' style={{position: value.position , flexDirection: value.flexDirection , top: value.top , right: value.right ,bottom: (width > 768 ? value.bottom : (i===0 && value.bottom ? "89%" : value.bottom)), left: value.left  , height: `calc(40vh * ${value.subHeight})`}} key={{i}}>
                            <div className='has-text-weight-medium businessImpact-sub-child-p' style={{width: value.subWidth}}>
                                <p  style={{textAlign: value.textAlign}}>{value.subDescription}</p>
                            </div>
                               
                            <div className='connecting-line' style={{flexDirection: value.flexDirection , position: impact.position , left: value.posLeft , right: value.posRight}}>
                                <p className='line-dot' style={{background: value.color , marginTop: (width > 768 ? value.marginTop: value.marginTop ? "0.5rem": value.marginTop), marginLeft: (width > 768 ? value.marginLeft: value.marginLeft ? "0.5rem": value.marginLeft), marginRight: (width > 768 ? value.marginRight: value.marginRight ? "0.5rem": value.marginRight), marginBottom: (width > 768 ? value.marginBottom: value.marginBottom ? "0.5rem": value.marginBottom)}}></p>
                                <p className='single-line' style={{height: value.height , width : value.width , flexDirection: value.flexDirection , border: `2px solid ${value.color}`}}></p>
                            </div>
                            <div className='businessImapct-hexagon has-text-weight-medium' style={{background: value.color}}>
                                <div style={{textAlign: 'center' , width: '70%' , paddingTop: '10%' , marginTop:'1rem'}}>
                                <p className='hexa-title' style={{color : impact.hexagonalColor }}>{value.title}</p>
                                </div>
                                <div className='businessImapct-hexagon-logo'>
                                    {image ? (
                                    <GatsbyImage image={logo} alt='Loading...' className='logo'/>
                                    ) : (
                                    <img src={value.logo} alt='Loading...' className='logo'/>
                                    )}
                                </div>      
                            </div>    
                        </div>
                    )})}          
                </div>
            </div>
        </div>
    )
}


BusinessImpact.propTypes = {
    businessImpact: PropTypes.shape({
        title: PropTypes.arrayOf(
            PropTypes.shape({
                fragment: PropTypes.string.isRequired,
                color: PropTypes.string.isRequired,
            })
        ).isRequired,
        description: PropTypes.string.isRequired,
        hexagonal: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string.isRequired,
                subDescription: PropTypes.string.isRequired,
                logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
                color: PropTypes.string.isRequired,
                height: PropTypes.string.isRequired,
                width: PropTypes.string.isRequired,
                subWidth: PropTypes.string.isRequired,
                subHeight: PropTypes.string.isRequired,
                flexDirection: PropTypes.string.isRequired,
                textAlign: PropTypes.string.isRequired,
                position: PropTypes.string.isRequired,
                bottom: PropTypes.string.isRequired,
                right: PropTypes.string.isRequired,
                top: PropTypes.string.isRequired,
                left: PropTypes.string.isRequired,
                marginTop: PropTypes.string.isRequired,
                marginLeft: PropTypes.string.isRequired,
                marginRight: PropTypes.string.isRequired,
                marginBottom: PropTypes.string.isRequired,
                posRight: PropTypes.string.isRequired,
                posLeft: PropTypes.string.isRequired,
              })
            ).isRequired,
        hexagonalImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        position: PropTypes.string.isRequired,
        hexagonalColor: PropTypes.string.isRequired,
        fontFamily: PropTypes.string.isRequired,
    }).isRequired,
}

export default BusinessImpact;