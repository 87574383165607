import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "../style/verticalCorausel2.sass";

const VerticalCarouselCard2 = ({content , index , showTitle}) => {

        const backgroundImageData = getImage(content.backgroundImage);
        const backgroundImageUrl = backgroundImageData?.images?.fallback?.src;
    
    return (
        <div
                className="verticalMainContainer fit-page"
                key= {index}
                style={{
                  backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%, rgba(102, 102, 102, 0) 100%), url(${backgroundImageUrl})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  position: 'relative'
                }}
              >
                <div className="vertical-sub-Container-1">
                  <h2>{content.title}</h2>
                  <p>{content.description}</p>
                  <button className="sub-container-1-button" style={{ background: '#E65E27'}}>
                    {content.button}
                  </button>
                </div>
               
                  <GatsbyImage  className='screen1' image={getImage(content.screen1)} alt="loading..." 
                    style={{position: content.positionOne ,left: content.leftOne , bottom: content.topOne , zIndex:content.zIndexOne}}
                  />
                  <GatsbyImage  className= 'screen2'  image={getImage(content.screen2)} alt="loading..." 
                    style={{position: content.positionTwo ,left: content.leftTwo , bottom: content.topTwo, zIndex:content.zIndexTwo}}
                  />
                  <GatsbyImage className='screen3' image={getImage(content.screen3)} alt="loading..." 
                    style={{position: content.positionThree ,left: content.leftThree , bottom: content.topThree, zIndex:content.zIndexThree}}
                  />
                
              </div>
    )
}

export default VerticalCarouselCard2;