import React, { useState , useEffect , useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PropTypes from 'prop-types';
import VerticalCarouselCard2 from './VerticalCarouselCard2';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const VerticalCarousel2 = ({ verticalDots, mainContent, currentSlide }) => {
  const [activeIndex, setActiveIndex] = useState(currentSlide || 0);
  const isScrollingRef = useRef(false);
  const [showTitle, setShowTitle] = useState(true);
  const sliderRef = useRef(null);

  useEffect(() => {
    
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  const handleScroll = (e) => {
    e.preventDefault();

    if (isScrollingRef.current) return;

    isScrollingRef.current = true;

    setTimeout(() => {
      if (e.deltaY > 0) {
        // Scrolling down
        sliderRef.current.slickNext();
        if (activeIndex < mainContent.length - 1) {
          setActiveIndex((prev) => {
            const newIndex = prev + 1;
            handleSlideChange(newIndex);
            return newIndex;
          });
        } else {
          scrollToSection('next-section');
        }
      } else if (e.deltaY < 0) {
        // Scrolling up
        sliderRef.current.slickPrev()
        
        if (activeIndex > 0) {
          setActiveIndex((prev) => {
            const newIndex = prev - 1;
            handleSlideChange(newIndex);
            return newIndex;
          });
        } else {
          scrollToSection('previous-section');
        }
      }
      isScrollingRef.current = false;
    }, 500);
  };


  useEffect(() => {
    const carouselContainer = document.querySelector('.customs-slider');
    carouselContainer.addEventListener('wheel', handleScroll);

    return () => {
      carouselContainer.removeEventListener('wheel', handleScroll);
    };
  }, [activeIndex]);


  const scrollToSection = (sectionId) => {
    const header = document.getElementById('header-navbar');
    const headerHeight = header ? header.offsetHeight : 0;
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionTop - headerHeight,
        behavior: 'smooth',
      });
    }
  };


  const settings = {
    customPaging: function (i) {
      return (
        <div
          className="vertical-carousel-main"
          style={{
            background: activeIndex === i ? verticalDots[i].hoverBackgroundColor : verticalDots[i].backgroundColor,
          }}
        >
          <GatsbyImage image={getImage(verticalDots[i].image)} alt="loading..." />
          <p style={{ color: 'white', width: '90%' }}>{verticalDots[i].title}</p>
        </div>
      );
    },
    dots: true,
    dotsClass: 'slick-dots custom-slider-dots custom-slick-add',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 1000,
    vertical: true,
    verticalSwiping: true,
    initialSlide: 0,
    beforeChange: (current, next) => {
      handleSlideChange(next);
      setShowTitle(false);
    
    },
    afterChange: () => {
      
      setTimeout(() => {
        setShowTitle(true);
      },1000);
    },
  };


  return (
    <div className="customs-slider" onWheel={handleScroll}>
      <Slider {...settings} ref={sliderRef}>
        {mainContent && mainContent.map((item, index) => (
          <VerticalCarouselCard2 content={item} index={index} showTitle={showTitle}></VerticalCarouselCard2>
        ))}
      </Slider>
    </div>
  );
};

  VerticalCarousel2.propTypes = {
    salientAnalytics: PropTypes.shape({
        verticalDots: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            title: PropTypes.string.isRequired,
            backgroundColor: PropTypes.string.isRequired,
            hoverBackgroundColor: PropTypes.string.isRequired,
          })
        ).isRequired,
        content: PropTypes.arrayOf(
            PropTypes.shape({
              backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
              title: PropTypes.string.isRequired,
              description: PropTypes.string.isRequired,
              button: PropTypes.string.isRequired,
              positionOne: PropTypes.string.isRequired,
              zIndexOne: PropTypes.string.isRequired,
              topOne: PropTypes.string.isRequired,
              screen1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
              screen2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
              screen3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
              leftOne: PropTypes.string.isRequired,
              positionTwo: PropTypes.string.isRequired,
              topTwo: PropTypes.string.isRequired,
              leftTwo: PropTypes.string.isRequired,
              topThree: PropTypes.string.isRequired,
              leftThree: PropTypes.string.isRequired,
            })
          ).isRequired,
    }).isRequired,
  }

export default VerticalCarousel2