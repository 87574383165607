import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import "../style/salient-style.sass";

const SalientFeatures = ({ title,features, onFeatureClick }) => {
  const chunkedFeatures = [];
  for (let i = 0; i < features.length; i += 2) {
    chunkedFeatures.push(features.slice(i, i + 2));
  }

  return (
    <section id="previous-section" class="section2 fit-page">
      <div className="salient-features">
        <div className='title-container'>
          <h2 className='salient-title'>
          {title.map((part, index) => (
                <span key={index} style={{ color: part.color }}> {part.fragment + " "}</span>
              ))}
        </h2>
        <hr className='title-underline'/>
        </div>
        <div className="features-container">
          {chunkedFeatures.map((featurePair, containerIndex) => (
            <div className="container-section" key={containerIndex}>
              {featurePair.map((feature, index) => {
                const image = getImage(feature.image);
                return (
                  <div key={feature.index} className={`feature${index + 1}`} style={{ backgroundColor: feature.backgroundColor }} onClick={() => onFeatureClick(feature.index)}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = feature.hoverBackgroundColor}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = feature.backgroundColor}>
                    <GatsbyImage image={image} className={`feature-image${index+1} image is-square`} style={{paddingTop: "0%"}} />
                    <p className='feature-title'>{feature.caption}</p>
                    <p className={`hover-text${index+1}`}>{feature.hoverText}</p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

SalientFeatures.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.shape({
      fragment: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFeatureClick: PropTypes.func.isRequired,
};

export default SalientFeatures;